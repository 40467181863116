<div class="card d-flex align-items-center justify-content-center p-4" *ngIf="loading">
    <div class="col-12 d-flex justify-content-center mr-2">
        <h2>Tempo Médio de Transporte - {{demandTimelineMovementHistory.averageTransportTotalTime}}</h2>
    </div>
    <div class="col-12 d-flex justify-content-center align-items-center flex-wrap">
        <p-tag icon="fa fa-solid fa-code" severity="dev"
            [value]="'Desenvolvimento - ' + demandTimelineMovementHistory.averageTransportDevelopment">
        </p-tag>
        <p-tag icon="fa fa-solid fa-cog" severity="homol"
            [value]="'Homologação - ' + demandTimelineMovementHistory.averageTransportHomologation">
        </p-tag>
        <p-tag icon="fa fa-solid fa-truck" severity="prod"
            [value]="'Produção - ' + demandTimelineMovementHistory.averageTransportProduction">
        </p-tag>
        <p-tag icon="fa fa-solid fa-check" severity="finish"
            [value]="'Concluída - ' + demandTimelineMovementHistory.averageTransportFinish">
        </p-tag>
    </div>
</div>

<iframe class="col-12 col-md-6 mt-4" frameborder="0" height="500" allowtransparency [src]="urlQuestion1">
</iframe>

<iframe class="col-12 col-md-6" frameborder="0" height="500" allowtransparency [src]="urlQuestion2">
</iframe>